import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InlineBoxLink = makeShortcode("InlineBoxLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1015px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a7445ea0ff3a31b435e0279f2b6ceaff/0a4b2/all-diagrams-v2-AddLiq.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB7eWys1iwf//EABwQAAIBBQEAAAAAAAAAAAAAAAACAQMREiEiMf/aAAgBAQABBQLGwu1J8pPxMMf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAASEyEIGh/9oACAEBAAY/AohFnnZbh//EABsQAQACAwEBAAAAAAAAAAAAAAEAESExURCB/9oACAEBAAE/IWwPkxBqbOx82Sy1cbiHADkf/9oADAMBAAIAAwAAABA//wD/xAAXEQADAQAAAAAAAAAAAAAAAAAAARFh/9oACAEDAQE/EHEy4f/EABcRAAMBAAAAAAAAAAAAAAAAAAABEWH/2gAIAQIBAT8QVaJp/8QAHRABAAICAgMAAAAAAAAAAAAAAQARIVExQXGBkf/aAAgBAQABPxAANV2VHyDiLgFfF1DBFSaGKJ3BT0biVdcK9e7n/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "all diagrams v2 AddLiq",
            "title": "all diagrams v2 AddLiq",
            "src": "/static/a7445ea0ff3a31b435e0279f2b6ceaff/0a4b2/all-diagrams-v2-AddLiq.jpg",
            "srcSet": ["/static/a7445ea0ff3a31b435e0279f2b6ceaff/f93b5/all-diagrams-v2-AddLiq.jpg 300w", "/static/a7445ea0ff3a31b435e0279f2b6ceaff/b4294/all-diagrams-v2-AddLiq.jpg 600w", "/static/a7445ea0ff3a31b435e0279f2b6ceaff/0a4b2/all-diagrams-v2-AddLiq.jpg 1015w"],
            "sizes": "(max-width: 1015px) 100vw, 1015px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`Each Materia liquidity pool is a trading venue for a WUSD and a ITEM ERC20 Interoperable. When a pool contract is created, its balances of each token are 0; in order for the pool to begin facilitating trades, someone must seed it with an initial deposit of each token. This first liquidity provider is the one who sets the initial price of the pool. They are incentivized to deposit an equal `}<em parentName="p">{`value`}</em>{` of both tokens into the pool. To see why, consider the case where the first liquidity provider deposits tokens at a ratio different from the current market rate. This immediately creates a profitable arbitrage opportunity, which is likely to be taken by an external party.`}</p>
    <p>{`When other liquidity providers add to an existing pool, they must deposit pair tokens proportional to the current price. If they don’t, the liquidity they added is at risk of being arbitraged as well. If they believe the current price is not correct, they may arbitrage it to the level they desire, and add liquidity at that price.`}</p>
    <h1 {...{
      "id": "pool-tokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#pool-tokens",
        "aria-label": "pool tokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pool tokens`}</h1>
    <p>{`Whenever liquidity is deposited into a pool, unique tokens known as `}<em parentName="p">{`Materia liquidity pool tokens (MP)`}</em>{` are minted and sent to the provider’s address. These tokens represent a given liquidity provider’s contribution to a pool. The proportion of the pool’s liquidity provided determines the number of liquidity tokens the provider receives. If the provider is minting a new pool, the number of liquidity tokens they will receive will equal sqrt(x `}{`*`}{` y), where x and y represent the amount of each token provided.`}</p>
    <p>{`Whenever a trade occurs, a 0.3% fee is charged to the transaction sender. This fee is distributed `}<em parentName="p">{`pro-rata`}</em>{` to all LPs in the pool upon completion of the trade. This fee could be changed via a Governance Proposal on each pair.`}</p>
    <p>{`To retrieve the underlying liquidity, plus any fees accrued, liquidity providers must “burn” their liquidity tokens, effectively exchanging them for their portion of the liquidity pool, plus the proportional fee allocation.`}</p>
    <p>{`As liquidity tokens are themselves tradable assets, liquidity providers may sell, transfer, or otherwise use their liquidity tokens in any way they see fit.`}</p>
    <p>{`Learn more with advanced topics:`}</p>
    <InlineBoxLink title="Understanding Returns" to="docs/materia/advanced-topics/understanding-returns" mdxType="InlineBoxLink" />
    <InlineBoxLink title="Fees" to="docs/materia/advanced-topics/fees" mdxType="InlineBoxLink" />
    <h1 {...{
      "id": "why-pools",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#why-pools",
        "aria-label": "why pools permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why pools?`}</h1>
    <p>{`Materia, like Uniswap, is unique in that it doesn’t use an order book to derive the price of an asset or to match buyers and sellers of tokens. Instead, Materia uses what are called Liquidity Pools.`}</p>
    <p>{`Liquidity is typically represented by discrete orders placed by individuals onto a centrally operated order book. A participant looking to provide liquidity or make markets must actively manage their orders, continuously updating them in response to the activity of others in the marketplace.`}</p>
    <p>{`While order books are foundational to finance and work great for certain usecases, they suffer from a few important limitations that are especially magnified when applied to a decentralized or blockchain-native setting. Order books require intermediary infrastructure to host the orderbook and match orders. This creates points of control and adds additional layers of complexity. They also require active participation and management from market makers who usually use sophsticated infrastructure and algorithms, limiting participation to advanced traders. Order books were invented in a world with relatively few assets being traded, so it is not surprising they aren’t ideal for an ecosystem where anyone can create their own token and those tokens usually have low liquidity. In sum, with the infrastrucural trade-offs presented by a platform like Ethereum, order books are not the native architecture for implementing a liquidity protocol on a blockchain.`}</p>
    <p>{`It is important to reiterate that a Pool is just a smart contract, operated by users calling functions on it. Swapping tokens is calling `}<inlineCode parentName="p">{`swap`}</inlineCode>{` on a Pool contract instance, while providing liquidity is calling `}<inlineCode parentName="p">{`deposit`}</inlineCode>{`.`}</p>
    <p>{`Just how end-users can interact with the Materia protocol through the Interface (which in turn interacts with the underlying contracts), developers can interact directly with the smart contracts and integrate Materia functionality into their own applications without relying on intermediaries or needing permission.`}</p>
    <h1 {...{
      "id": "developer-resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#developer-resources",
        "aria-label": "developer resources permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Developer resources`}</h1>
    <ul>
      <li parentName="ul">{`To see how to pool tokens in a smart contract read `}<a parentName="li" {...{
          "href": "/docs/materia/smart-contract-integration/providing-liquidity/"
        }}>{`Providing Liquidity`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      